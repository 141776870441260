<template>
  <b-modal v-model="modalUpdate" size="xl" no-fade ref="edit-expense" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Factura</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <div class="row pt-4 mb-3">
              <div class="form-group col-lg-6" v-if="!setRecord">
                <label for="record_id">Expediente</label>
                <v-select
                  v-model="payload.record_id"
                  :options="records_dropdown"
                  id="record_id"
                  :reduce="r => r.code"
                >
                  <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                </v-select>
                <div v-if="errors.hasOwnProperty('record_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.record_id[0]}}</small>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label for="type_id">Tipo</label>
                <select v-model="payload.type_id" name="type_id" id="type_id" class="form-control form-control-sm">
                  <option value="1">Factura para consumidor final</option>
                  <option value="2">Factura de crédito fiscal</option>
                  <option value="3">Régimen especial de tributación</option>
                  <option value="4">Factura gubernamental</option>
                  <option value="5">Proforma</option>
                </select>
                <div v-if="errors.hasOwnProperty('type_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.type_id[0]}}</small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-lg-6">
                <label for="due_date">Fecha de Factura</label>
                <datetime v-model="payload.due_date" :config="dateConfig" id="due_date" class="form-control"></datetime>
                <div v-if="errors.hasOwnProperty('due_date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.due_date[0]}}</small>
                </div>
              </div>
              <div class="form-group col-lg-6">
                <label for="due_date">Fecha de Pago</label>
                <datetime v-model="payload.payment_date" :config="dateConfig" id="payment_date" class="form-control"></datetime>
                <div v-if="errors.hasOwnProperty('payment_date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.payment_date[0]}}</small>
                </div>
              </div>
            </div>
            <div class="row mb-5 justify-content-center align-items-center">
              <div class="col-12">
                <div class="table-responsive mb-5">
                  <table class="table table-hover">
                    <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Cantidad</th>
                      <th scope="col">Descripción</th>
                      <th scope="col">Monto</th>
                      <th scope="col">Subtotal</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in payload.items" :key="'item-'+index">
                      <th scope="row">{{ index + 1 }}</th>
                      <td>
                        <input v-model="item.qty" type="number" class="form-control" :class="errors.hasOwnProperty('items.'+index+'.qty') ? 'is-invalid' : ''" min="1"/>
                      </td>
                      <td>
                        <input v-model="item.description" type="text" class="form-control" :class="errors.hasOwnProperty('items.'+index+'.description') ? 'is-invalid' : ''" />
                      </td>
                      <td>
                        <input v-model="item.amount" type="number" class="form-control" :class="errors.hasOwnProperty('items.'+index+'.amount') ? 'is-invalid' : ''">
                      </td>
                      <td>
                        <input type="text" class="form-control disabled" :value="((item.qty * item.amount) ? item.qty * item.amount : 0) | formatMoney">
                      </td>
                      <td>
                        <button @click.prevent="removeItem(index)" class="btn btn-sm btn-danger">Eliminar</button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-gradient-info btn-sm" @click.prevent="addItem">
                  <i class="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="notes">Notas</label>
                  <textarea v-model="payload.notes" name="notes" id="notes" cols="15" rows="5" class="form-control"></textarea>
                  <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.notes[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 text-right pt-3">
                <div class="row mb-2">
                  <div class="col-4">
                    <span class="font-weight-bold">Subtotal</span>
                  </div>
                  <div class="col-8">
                    <span>{{ subtotal | formatMoney }}</span>
                  </div>
                </div>
                <div class="row mb-2 align-items-center">
                  <div class="col-4">
                    <span class="font-weight-bold">Impuestos</span>
                  </div>
                  <div class="col-4">
                    <input v-model.number="payload.tax" type="number" min="1" class="form-control form-sm" required />
                  </div>
                  <div class="col-4">
                    <span>{{ itbis | formatMoney }}</span>
                  </div>
                </div>
                <div class="row mb-2" v-if="payments > 0">
                  <div class="col-4">
                    <span class="font-weight-bold">Pagos</span>
                  </div>
                  <div class="col-8">
                    <span class="text-success font-italic">- {{ payments | formatMoney }}</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-4">
                    <span class="font-weight-bold">Total</span>
                  </div>
                  <div class="col-8">
                    <span>{{ total | formatMoney }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="updateResource" class="btn btn-gradient-info btn-md">Actualizar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mt-2 mb-4">
              <div class="col-auto">
                <h6 class="text-info font-weight-bold p-0 m-0">Pagos</h6>
              </div>
              <div class="col-auto ml-auto" v-if="balance > 0">
                <button @click.prevent="PAYMENT_TOGGLE_MODAL_CREATE" class="btn btn-sm btn-outline-info">Agregar</button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <datatable :api="'/api/payments?invoice_id=' + payload.id" :fields="paymentFields" label="payment" :hide-search="true"></datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <payment-update :setInvoice="payload.id" :currentBalance="balance" :payload="paymentSelected" label="payment"></payment-update>
    <payment-create :setInvoice="payload.id" :currentBalance="balance" label="payment"></payment-create>
  </b-modal>
</template>

<script>
import Datatable from '@/components/TableLayout'
import PaymentUpdate from '@/components/EditPayment'
import PaymentCreate from '@/components/CreatePayment'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'UpdateInvoice',
  props: {
    payload: {
      type: Object,
      required: true
    },
    setRecord: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      errors: {},
      selected: {},
      paymentSelected: {},
      paymentFields: [
        {
          name: 'id',
          title: '#'
        },
        {
          name: 'payment',
          title: 'Fecha'
        },
        {
          name: 'amount',
          title: 'Monto'
        },
        {
          name: 'invoice',
          title: 'Factura #',
          formatter: value => {
            return 'Fact #. ' + value.id
          }
        }
      ],
      dateConfig: {
        altInput: true,
        enableTime: false,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      },
      modalOptions: {
        id: 'modalLogout',
        msg: '¿Seguro que desea eliminar este registro?',
        title: 'Eliminando registro',
        okVariant: 'danger',
        okTitle: 'Sí, estoy seguro',
        cancelTitle: 'No, cancelar',
        dialogClass: 'tms-modal',
        noCloseOnBackdrop: true,
        hideBackdrop: true,
        centered: true
      }
    }
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler (value) {
        const today = this.$moment()
        if (this.$moment(value.date).isBefore(today, 'd')) {
          this.errors.date = ['La fecha es inferior al día de hoy']
        } else {
          this.errors.date = ''
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_MODAL_UPDATE: 'Invoice/TOGGLE_MODAL_UPDATE',
      PAYMENT_TOGGLE_MODAL_CREATE: 'Payment/TOGGLE_MODAL_CREATE',
      PAYMENT_TOGGLE_MODAL_UPDATE: 'Payment/TOGGLE_MODAL_UPDATE'
    }),
    ...mapActions({
      update: 'Invoice/update',
      create: 'Invoice/create',
      show: 'Invoice/show',
      getRecordsDropdown: 'Record/getRecordsDropdown'
    }),
    updateResource () {
      this.payload.subtotal = this.subtotal
      this.payload.itbis = this.itbis
      this.payload.total = this.total
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          // if (!error.response.data.errors) {
          this.$swal(error.response.data.message, '', 'error')
          // }
        })
    },
    reset () {
      this.errors = {}
      this.$events.fire('refresh-table')
      this.TOGGLE_MODAL_UPDATE(false)
    },
    resetTables () {
      this.$events.fire('filter-reset')
    },
    addItem () {
      this.payload.items.push({
        id: '',
        qty: 1,
        description: '',
        amount: ''
      })
    },
    removeItem (index) {
      this.$bvModal.msgBoxConfirm(this.modalOptions.msg, this.modalOptions)
        .then(value => {
          if (value) {
            this.$delete(this.payload.items, index)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  computed: {
    ...mapGetters({
      modalUpdate: 'Invoice/modalUpdate',
      records_dropdown: 'Record/records_dropdown'
    }),
    subtotal () {
      const subtotal = this._.sumBy(this.payload.items, function (o) {
        if (o.amount > 0) {
          return (parseFloat(o.amount) * parseInt(o.qty))
        }
      })
      return subtotal > 0 ? subtotal : 0
    },
    itbis () {
      const itbis = this.subtotal * Number(this.payload.tax)
      return itbis > 0 ? itbis : 0
    },
    total () {
      const total = this.subtotal + this.itbis
      return total > 0 ? total : 0
    },
    payments () {
      const payments = this._.sumBy(this.payload.payments, function (o) {
        if (o.amount > 0) {
          return parseFloat(o.amount)
        }
      })
      return payments > 0 ? payments : 0
    },
    balance () {
      return this.total - this.payments
    }
  },
  mounted () {
    this.$events.$on('table-item-edit', (eventData, label) => {
      if (label === 'payment') {
        this.paymentSelected = eventData
        this.PAYMENT_TOGGLE_MODAL_UPDATE()
      }
    })
    this.$events.$on('refresh-invoice', (eventData, label) => {
      this.show(this.payload)
        .then((response) => {
          this.payload = response.data
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.$swal(error.response.data.message, '', 'error')
        })
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
    this.$events.off('refresh-invoice')
  },
  components: {
    Datatable,
    PaymentUpdate,
    PaymentCreate
  }
}
</script>
