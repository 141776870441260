<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header">
          <h3 class="page-title">Facturas</h3>
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"> Facturas </a></li>
              <li class="breadcrumb-item active" aria-current="page">Todas las facturas</li>
            </ol>
          </nav>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row justify-content-between align-items-center mb-4">
                  <div class="col-auto ml-auto">
                    <button class="btn btn-sm btn-gradient-info" @click.prevent="openModelCreate">
                      <i class="mdi mdi-plus"></i> Nuevo
                    </button>
                  </div>
                </div>
                <datatable :api="apiEndPoint" :fields="fields"></datatable>
              </div>
            </div>
          </div>
        </div>
        <modal-create></modal-create>
        <modal-update v-if="selected" :payload="selected"></modal-update>
        <client-modal-update :payload="clientSelected"></client-modal-update>
        <b-modal
          v-model="paymentModal"
          no-fade
          size="md"
          ref="add-payment"
          :no-close-on-backdrop="true"
          hide-footer
          hide-header
          hide-backdrop
        >
          <div class="row">
            <div class="col-md-12 stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="row mb-4 align-items-center">
                    <div class="col-4 col-md-auto">
                      <h4 class="card-title">Agregar Pago</h4>
                    </div>
                    <div class="col-2 col-md-auto ml-auto">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetPaymentModal">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                  </div>
                  <form class="forms-sample">
                    <div class="row">
                      <div class="form-group col-4">
                        <p class="mb-1">Factura:</p>
                        Fact #{{selected.id}}
                      </div>
                      <div class="form-group col-4">
                        <p class="mb-1">Total</p>
                        {{selected.total | formatMoney}}
                      </div>
                      <div class="form-group col-4">
                        <p class="mb-1">Pendiente</p>
                        {{payment.pending | formatMoney}}
                      </div>
                      <div class="form-group col-12 mt-3">
                        <label for="payment_method">Método de pago</label>
                        <select v-model="payment.payment_method" name="payment_method" id="payment_method" class="form-control">
                          <option value="1">Efectivo</option>
                          <option value="2">Depósito / Transferencia</option>
                        </select>
                        <div v-if="errors.hasOwnProperty('payment_method')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                          <small>{{errors.payment_method[0]}}</small>
                        </div>
                      </div>
                      <div class="form-group col-12">
                        <label for="amount">Monto</label>
                        <input v-model="payment.amount" type="text" class="form-control" id="amount" placeholder="Monto">
                        <div v-if="errors.hasOwnProperty('amount')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                          <small>{{errors.amount[0]}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-end">
                      <div class="col-auto">
                        <button @click.prevent="markPaid" class="btn btn-gradient-info btn-md">Guardar</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <create-payment :setInvoice="selected" :currentBalance="payment.pending"></create-payment>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import Datatable from '@/components/TableLayout'
import ModalCreate from '@/components/CreateInvoice'
import ModalUpdate from '@/components/EditInvoice'
import ClientModalUpdate from '@/components/EditClient'
import CreatePayment from '@/components/CreatePayment'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Invoices',
  data () {
    return {
      apiEndPoint: 'api/invoices',
      fields: [
        {
          name: 'invoice_number',
          title: '#'
        },
        {
          name: 'record',
          title: 'Expediente'
        },
        {
          name: 'total',
          title: 'Monto',
          formatter: value => {
            return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'DOP' }).format(value)
          }
        },
        {
          name: 'invoice_payments',
          title: 'Pagos'
        },
        {
          name: 'created_at',
          title: 'Facturado',
          formatter: value => {
            const date = this.$moment(value)
            return date.format('DD-MM-YYYY')
          }
        },
        {
          name: 'payed',
          title: 'Estado',
          titleClass: 'text-center',
          dataClass: 'text-center',
          formatter: value => {
            if (value) {
              return '<span class="badge badge-info">Pagado</span>'
            }
            return '<span class="badge badge-info">Pendiente</span>'
          }
        },
        {
          name: 'invoice_actions',
          title: 'Acción'
        }
      ],
      selected: '',
      paymentModal: false,
      clientSelected: {},
      payment: {
        payment_method: 1,
        amount: 0
      },
      errors: {}
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_MODAL_CREATE: 'Invoice/TOGGLE_MODAL_CREATE',
      TOGGLE_MODAL_UPDATE: 'Invoice/TOGGLE_MODAL_UPDATE',
      CLIENT_TOGGLE_MODAL_UPDATE: 'Client/TOGGLE_MODAL_UPDATE',
      TOGGLE_PAYMENT_MODAL_CREATE: 'Payment/TOGGLE_MODAL_CREATE'
    }),
    ...mapActions({
      getRecordsDropdown: 'Record/getRecordsDropdown',
      setStatusPaid: 'Invoice/setStatusPaid'
    }),
    openModelCreate () {
      this.TOGGLE_MODAL_CREATE()
    },
    openModelUpdate () {
      this.TOGGLE_MODAL_UPDATE()
    },
    openClientModelUpdate () {
      this.CLIENT_TOGGLE_MODAL_UPDATE()
    },
    resetPaymentModal () {
      this.paymentModal = false
      this.selected = {}
      this.errors = {}
      this.payment = {
        payment_method: 1,
        amount: 0
      }
    },
    markPaid () {
      this.setStatusPaid({
        invoice: this.selected,
        payment: this.payment
      })
        .then(() => {
          this.$events.fire('refresh-table')
          this.$swal('Operación Completada', '', 'success')
          this.resetPaymentModal()
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.$swal(error.response.data.message, '', 'error')
        })
    }
  },
  mounted () {
    this.getRecordsDropdown({
      orderBy_expenses: 'desc'
    })
    this.$events.$on('table-item-edit', (eventData, label) => {
      if (label === 'payment') {
        return
      }
      this.selected = eventData
      this.openModelUpdate()
    })
    this.$events.$on('table-client-edit', eventData => {
      this.clientSelected = eventData
      this.openClientModelUpdate()
    })
    this.$events.$on('table-item-invoice-set-payed', (eventData, label) => {
      this.selected = eventData
      this.payment.amount = this.selected.total - this._.sumBy(this.selected.payments, (i) => parseFloat(i.amount))
      this.payment.pending = this.selected.total - this._.sumBy(this.selected.payments, (i) => parseFloat(i.amount))
      this.TOGGLE_PAYMENT_MODAL_CREATE()
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
    this.$events.off('table-client-edit')
    this.$events.off('table-item-invoice-set-payed')
  },
  components: {
    Layout,
    Datatable,
    ClientModalUpdate,
    ModalCreate,
    ModalUpdate,
    CreatePayment
  }
}
</script>
